@import "~react-dates/lib/css/_datepicker.css";

.DateInput_input,
.DateInput_input__focused {
    border-bottom: 1px solid transparent; //don't remove this needed for height calculation
    background: transparent;
}

.SingleDatePicker_picker {
    z-index: 100;
    margin-top: -10px;
}

.SingleDatePickerInput {
    border-color: $input-border-color;
}

.CalendarDay {
    vertical-align: middle;
}

.CalendarDay__selected {
    border: 1px double $blue-dark;
    background: $blue-dark;

    &:hover {
        background: $blue-dark;
        border: 1px double $blue-dark;
        color: #fff;
    }
}
