.table-actions {
    padding: 0.5rem;
    background: $white;

    label {
        font-size: $size-7;
    }
}

.table-filters {
    padding: 0.5rem;
    background: $white;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    .icon-container {
        color: $grey;
        align-self: center;

        span {
            margin-right: 0.25rem;
        }

        .fa {
            font-size: 0.8rem;
        }
    }

    .filters-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        div {
            margin-left: 0.1rem;
        }
    }
}

.table {
    width: 100%;

    thead {
        background: $border;
    }

    th,
    td {
        color: $text;
        vertical-align: middle;

        &.nowrap {
            white-space: nowrap;
        }
    }

    tr {
        &.is-active {
            td:first-of-type {
                box-shadow: inset 4px 0 0 $primary;
            }
        }

        td:last-of-type {
            width: 1px;
            white-space: nowrap;
        }

        &:hover {
            background: rgba($color: $border, $alpha: 0.35);
        }
    }

    .table-actions-row,
    .table-filters-row {
        td {
            padding: 0;
            border-bottom: 1px solid $border-hover;
        }
    }

    .button {
        margin-bottom: 0;
    }
    .buttons {
        margin: 0;
        display: block;
    }

    .is-checkbox-cell {
        padding: 0 0.25rem;
        position: relative;
        text-align: center;
    }

    &.is-fixed {
        table-layout: fixed;
    }

    &.top-align {
        th,
        td {
            vertical-align: top;
        }
    }

    &.files-table {
        table-layout: fixed;
        vertical-align: middle;

        td {
            padding-left: 0;
            padding-right: 0;

            &.filename {
                text-overflow: ellipsis;
                white-space: nowrap;
                width: auto;
                overflow: hidden;
            }

            &.action {
                width: 30px;
                text-align: right;
            }
        }
    }
}
