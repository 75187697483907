input[type="checkbox"],
input[type="radio"] {
    cursor: pointer;
}

.label {
    font-size: $size-6;
}

label {
    &.checkbox {
        padding-right: 0.5rem;
    }
}

.field {
    align-items: center;

    &.is-narrow {
        max-width: 120px;
    }
}
