.button {
    background-color: transparent;
    border-color: $blue-dark;
    color: $blue-dark;

    &:not(.is-text):hover {
        background-color: $blue-dark;
        border-color: $blue-dark;
        color: $white;
    }

    &.submit {
        background-color: $blue-dark;
        border-color: $blue-dark;
        color: $white;

        &:not(.is-text):hover {
            background-color: transparent;
            border-color: $blue-dark;
            color: $blue-dark;
        }
    }

    &.cancel {
        background-color: transparent;
        border-color: $blue-dark;
        color: $blue-dark;

        &:not(.is-text):hover {
            background-color: $blue-dark;
            border-color: $blue-dark;
            color: $white;
        }
    }

    &.is-transparent {
        background: transparent;
        border-color: transparent;

        &:hover,
        &:focus {
            background: transparent;
            border-color: transparent;
            color: $blue-dark;
        }
    }
}

.button-link {
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    outline: none;
    color: $blue-dark;

    &:hover,
    &:focus {
        color: $black;
    }
}
