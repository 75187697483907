.react-select-container {
    min-width: 200px;

    & + .react-select-container {
        margin-left: 10px;
    }

    .react-select__control {
        border-radius: $radius;
        z-index: 10;
    }

    .react-select__menu {
        z-index: 11;
    }
}
