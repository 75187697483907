.navbar {
    box-shadow: 0.25rem 0 0.25rem $grey-light;

    .navbar-menu {
        margin: 0 0.5rem;

        .navbar-search {
            position: relative;
        }
    }

    .navbar-brand {
        padding: 0;
    }

    .logo-link {
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;

        a {
            display: flex;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        img {
            max-height: 38px;
            width: auto;
        }
    }

    .user-profile-icon {
        border: 1px solid $white;
        box-shadow: 0 0 2px $blue;
        background: $blue;
        color: $white;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1rem;
        overflow: hidden;

        .fa {
            font-size: 1.5rem;
        }
    }
}

.basket-dropdown {
    .basket-count {
        margin: -0.5rem 0 0 0;
        font-size: 9px;
        padding: 2px 6px;
        border-radius: 10px;
        border: 1px solid $white;
        color: white;
        background: $primary;
        line-height: initial;
    }
}

.fa-envelope {
    padding-left: inherit;
}
