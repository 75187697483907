// Typography
$family-sans-serif: "Arial", "Helvetica", sans-serif;
$family-monospace: monospace;
$render-mode: optimizeLegibility;

$size-1: 2.75rem;
$size-2: 2.25rem;
$size-3: 1.75rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;
$size-8: 0.5rem;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 64px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px;
// 960px container + 4rem
$desktop: 960px + (2 * $gap);
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap);
$widescreen-enabled: true;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap);
$fullhd-enabled: true;

// Miscellaneous

$easing: ease-out;
$radius-small: 2px;
$radius: 4px;
$radius-large: 6px;
$radius-rounded: 290486px;
$speed: 86ms;

// Flags

$variable-columns: true;
