.auth-layout {
    height: 100%;
    background: $blue;
    display: flex;
    align-items: center;

    .columns {
        border-radius: $radius-large;
        overflow: hidden;
        box-shadow: 0 0 2rem rgba(0, 0, 0, 0.25);
    }

    .column {
        background: $white;

        &.has-key-visual {
            background: url("/plantserv-visual.jpg") no-repeat center;
            background-size: cover;
        }
    }

    .form-header {
        text-align: center;
        padding: 2rem 0 2rem;

        img {
            max-height: 64px;
            margin-bottom: 1rem;
        }
    }

    .auth-form {
        padding: 2rem;

        .buttons {
            margin-bottom: 0.25rem;
            justify-content: space-between;
        }
    }
}
