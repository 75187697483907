@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";

.noty_effects_open {
    opacity: 0;
    transform: translate(25%);
    animation: noty_anim_in 0.25s easeOutBack;
}

.noty_effects_close {
    animation: noty_anim_out 0.25s easeOutBack;
}

@keyframes noty_anim_in {
    100% {
        transform: translate(0);
        opacity: 1;
    }
}

@keyframes noty_anim_out {
    100% {
        transform: translate(25%);
        opacity: 0;
    }
}
