// General
$body-background-color: #f8fafc;
$body-size: 14.4px;
$body-rendering: optimizeLegibility;
$body-family: $family-primary;
$body-color: $text;
$body-weight: $weight-normal;
$body-line-height: 1.5;

// Title
$title-weight: $weight-normal;
$title-color: $blue-dark;

// Menu
$menu-item-color: $white;
$menu-item-radius: $radius-small;
$menu-item-hover-color: $grey-light;
$menu-item-hover-background-color: transparent;
$menu-item-active-color: $link-invert;
$menu-item-active-background-color: $blue-darker;
$menu-list-border-left: 1px solid $border;
$menu-label-color: $white;

// Navbar
$navbar-height: 5rem;
$navbar-item-color: $text;
$navbar-item-hover-color: $blue;
$navbar-item-hover-background-color: transparent;
$navbar-breakpoint: $tablet;

// Forms
$label-color: $text;
// $label-weight: $weight-normal;
$input-shadow: 0;
$input-focus-box-shadow-size: 0;
$input-icon-color: $grey-light;
// $input-icon-active-color:

// Modal
$modal-card-spacing: 16px;
$modal-card-head-background-color: $white;
$modal-card-head-border-bottom: 1px solid $border;
$modal-card-head-padding: 16px;
$modal-card-head-radius: $radius;

$modal-card-title-color: $text;
$modal-card-title-line-height: 1;
$modal-card-title-size: $size-5;

$modal-card-foot-radius: $radius;
$modal-card-foot-border-top: 1px solid $border;

$modal-card-body-background-color: $white;
$modal-card-body-padding: 16px;

// Table Vars
$table-color: $text;
$table-background-color: transparent;

$table-cell-border: 1px solid $grey-lighter;
$table-cell-border-width: 0 0 1px;
$table-cell-padding: 0.5em;
$table-cell-heading-color: $text;

$table-head-cell-border-width: 0 0 1px;
$table-head-cell-color: $text;
$table-foot-cell-border-width: 1px 0 0;
$table-foot-cell-color: $text;

$table-row-hover-background-color: $white-ter;

$table-row-active-background-color: $primary;
$table-row-active-color: $primary-invert;

$table-striped-row-even-background-color: $white-ter;
$table-striped-row-even-hover-background-color: $grey-lighter;
