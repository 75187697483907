@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
@import "colors.scss";
@import "variables.scss";
@import "defaults.scss";
@import "overrides.scss";
@import "~bulma";
@import "~rbx/rbx";
@import "fonts.scss";
@import "helpers.scss";
@import "typography.scss";

@import "./components/table.scss";
@import "./components/menu.scss";
@import "./components/navbar.scss";
@import "./components/page-header.scss";
@import "./components/pane.scss";
@import "./components/document-viewer.scss";
@import "./components/accordion.scss";
@import "./components/forms.scss";
@import "./components/search.scss";
@import "./components/folder-filter.scss";
@import "./components/notifications.scss";
@import "./components/paging.scss";
@import "./components/modal.scss";
@import "./components/tag-details.scss";
@import "./components/button.scss";
@import "./components/popover.scss";
@import "./components/date-picker.scss";
@import "./components/infinity-scroller";
@import "./components/plant-tile.scss";
@import "./components/tag-viewer.scss";
@import "./components/react-select.scss";
@import "./components/dropzone.scss";
@import "./components/auth-page.scss";
@import "./components/feedback-modal.scss";

html,
body,
#root,
.main-layout {
    height: 100%;
    overflow: hidden;
}

.main-layout {
    display: flex;
}

.content-layout {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.content-container {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.dropdown-content {
    max-height: 20em;
    overflow: auto;
}

.attachment-btn {
    margin-right: auto;
}

// override for number up down buttons
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

label.checkbox input[type="checkbox"] {
    vertical-align: middle;
}
