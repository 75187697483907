.accordion {
    min-width: 280px;
    border: 1px solid $border;
    background: $white;

    .accordion-title {
        background: $border;
        padding: 0.75rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: $weight-bold;
        cursor: pointer;
    }

    .accordion-content {
        padding: 0.75rem 1rem 1.25rem;

        .level {
            .level-left {
                flex: 1;
                min-width: 0;
            }
        }
    }

    .accordion-header {
        font-weight: $weight-bold;
        color: $black-ter;
        padding: 0.25rem 0 0.5rem;
    }
}
