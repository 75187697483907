.pane {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    border: 1px solid $border;
    border-radius: $radius;
    background-color: $white;

    .pane-header {
        background: $white;
        border-bottom: 1px solid $border;
        height: $navbar-height;
        min-height: $navbar-height;
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pane-title {
            font-size: $size-5;
        }
    }

    .pane-content {
        padding: 1rem;
        flex-grow: 1;
        overflow-y: auto;

        .search-result-paging {
            top: 20px;
        }
    }

    &.flex-column-content {
        .pane-content {
            display: flex;
            flex-direction: column;
        }
    }

    &.pane-content-relative .pane-content {
        position: relative;
    }

    .pane-footer {
        background: $white;
        border-top: 1px solid $border;
        min-height: $navbar-height;
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &.with-danger {
            justify-content: flex-start;
        }

        &.with-action.with-danger {
            justify-content: space-between;
        }
    }

    &.side-pane {
        position: fixed;
        z-index: 999;
        top: 0;
        right: 0;
        bottom: 0;
        width: 80%;
        min-width: 960px;
        box-shadow: 0 0 1.5rem $shadow;
    }
}
