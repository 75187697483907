.popover-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    background: rgba($color: $black, $alpha: 0.35);
}

.popover {
    $popBorder: $blue;
    position: absolute;
    display: inline-block;
    text-align: center;
    transform: translateX(-50%) translateY(6px);
    z-index: 10001;
    box-shadow: 0 0 15px rgba($color: $black, $alpha: 0.5);
    border-radius: $radius;
    max-width: 500px;
    overflow: hidden;

    // arrow
    // &:before {
    //     content: "";
    //     width: 0;
    //     height: 0;
    //     position: absolute;
    //     border: solid transparent;
    //     border-bottom-color: $popBorder;
    //     border-width: 10px;
    //     margin-left: -10px;
    //     bottom: 100%;
    //     left: 50%;
    // }

    .popover-title {
        color: $white;
        background: $popBorder;
        padding: 0.25rem 1rem;
        font-weight: $weight-bold;

        .click-options {
            border-radius: 50%;
            padding: 0.25rem;
            margin: 0 0.25rem;
            cursor: pointer;
        }

        .title-options-container {
            color: $white;
            border: 1px solid $white;
            border-radius: $radius;
            padding: 0.25rem 0.25rem 0;
            margin: 0.25rem 0 1rem;

            strong {
                display: block;
                color: $white;
                font-weight: $weight-bold;
            }
        }

        .title-option {
            display: flex;
            cursor: pointer;
            padding: 0.15rem 0.25rem;
        }

        .title-option,
        .click-options {
            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .popover-body {
        padding: 1rem;
        background: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &.search-tips {
        .popover-body .content {
            text-align: left;

            ul {
                margin-top: 0;

                li + li {
                    margin-top: 0.5rem;
                }
            }
        }
    }
}
