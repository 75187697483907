.document-viewer-header {
    display: flex;
    width: calc(100% - 36px);
    align-items: center;

    .document-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: $size-6;
        padding-right: 0.5rem;
        flex: 1 1 auto;
        display: flex;
        align-items: center;

        .button {
            margin-right: 0.5rem;
        }
    }

    .document-paging {
        flex: 0 0 auto;
        padding: 0 0.5rem;

        .paging {
            border: 0;
        }
    }

    .document-controls {
        flex: 0 0 auto;
        padding-left: 0.5rem;
    }

    .button {
        margin: 0;
    }
}

.pane {
    &.show-tags {
        .word {
            &.is-tag {
                background-color: rgba(0, 90, 0, 0.2);
            }
        }
    }

    &.show-words {
        .word {
            border: 1px solid green;
            padding: 0;
        }
    }
}

.document-viewer {
    border: 1px solid $border;
    display: flex;
    height: 100%;
    flex-direction: column;
    background: $white;

    .document-header {
        display: block;
        background: $grey;
        padding: 0.5rem;

        .column {
            align-self: center;
        }

        .title {
            color: $white;
        }

        .buttons {
            .button {
                margin: 0;
            }
        }
    }

    .zoom-wrapper {
        overflow: hidden;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        -ms-overflow-style: none;
        overflow: auto;
        cursor: grab;

        .image-container {
            &.has-transition {
                transition: transform 0.1s ease-out;
            }

            .inner {
                display: block;
                margin: 0 auto;

                img {
                    max-height: calc(100vh - 10rem);
                    display: block;
                }

                .words-layer {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    overflow: hidden;

                    .word {
                        position: absolute;
                        cursor: pointer;

                        &.word-highlighted {
                            opacity: 0.4;
                            background: $cyan;
                            padding: 4px;
                            margin: -2px 0 0 -2px;
                            z-index: 5;
                        }

                        &:hover {
                            background: fade-out($color: $turquoise, $amount: 0.6);
                            margin: -2px 0 0;
                        }

                        &.line-highlighted {
                            opacity: 0.2;
                            background: $cyan;
                            padding: 4px;
                            margin: -2px 0 0 -2px;
                        }

                        &.has-options {
                            z-index: 2;
                        }

                        &.dev-highlight {
                            background: rgba($color: $red, $alpha: 0.6);
                            border: 1px solid green;
                            color: $white;
                            padding: 0;
                            text-indent: 0;
                            white-space: initial;
                        }
                    }
                }
            }
        }

        .pan-container {
            .inner,
            .pan-container .inner img {
                width: auto;
                height: auto;
            }
        }
    }

    &.has-wheel-zoom {
        .zoom-wrapper {
            overflow: auto;
        }
    }

    .document-path {
        position: absolute;
        bottom: 1px;
        left: 0;
        margin: 2px;
        padding: 4px;

        &.open {
            border: 1px solid #00a1e0;
            border-radius: 3px;
            background: white;
            width: calc(100% - 2px);
            margin-left: 1px;
            margin-bottom: 1px;
        }

        .document-path-bar {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        small {
            display: inline-block;
            word-break: break-all;
        }
    }
}

#deleteModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    margin: auto;
    min-width: 400px;
    max-width: 800px;
    flex: 1 0 100%;
    height: 100%;
    justify-content: center;

    .delete-modal-content {
        width: 100%;
        background: white;
        border-radius: 1rem;

        .delete-modal-title {
            padding: 1rem;
            font-size: 1.5rem;
        }

        .delete-modal-body {
            border-top: 1px solid $cyan;
            border-bottom: 1px solid $cyan;
            padding: 1rem;
        }

        .delete-modal-actions {
            padding: 1rem;
            display: flex;
            justify-content: flex-end;

            .button:last-of-type {
                margin-left: 0.5rem;
            }
        }
    }
}
