.plant-tile {
    border-radius: $radius;

    .plant-tile-img {
        display: block;
        height: 100%;
        width: 100%;
        margin-bottom: 1rem;
    }

    .button {
        margin-bottom: 0.5rem;
    }
}
