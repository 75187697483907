.folder-modal-card {
    width: 60%;
    height: 60%;
}

.folder-modal-card-body {
    overflow-x: auto !important;
    white-space: nowrap;
}

.folder-node {
    border: 1px solid;
    cursor: pointer;
    padding: 0 1rem 0 1rem;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.25em;
    display: grid;
    grid-template-columns: auto auto 1fr;
    justify-content: left;
    align-items: center;
    column-gap: 0.5rem;
}

.folder-node-active {
    background-color: $grey-lighter;
}
