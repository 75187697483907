.menu {
    background: $blue-dark;
    min-width: 220px;
    height: 100%;

    .menu-label {
        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: $navbar-height;
            padding: 0 1rem;
            background: $blue-darker;
            color: $white;

            &:hover {
                color: $grey-light;
            }
        }
    }

    .menu-list {
        a {
            i {
                width: 2rem;
            }
        }
    }

    .plant-list {
        padding-bottom: 0.5rem;

        li a {
            padding-left: 1rem;
            display: block;
        }
    }
}
