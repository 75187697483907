.page-header {
    flex: 0 1 auto;
    padding: 0.25rem 0 0.5rem;

    .title {
        font-weight: $weight-bold;
    }

    .columns {
        align-items: center;

        .column {
            .button {
                font-size: 14px;
            }
        }
    }
    hr {
        content: "";
        background: $grey-light;
        margin: 0.25rem 0 1rem;
    }
}
