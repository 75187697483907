.search-form {
    display: flex;
    align-self: center;

    .search-input {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 0;
        transition: box-shadow 200ms ease-in;

        &:hover {
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        }

        input {
            border: 0;
            color: $text;
            background: transparent;
            height: 100%;
            margin-left: 0.25rem;
            padding: 0 0.5rem;
            outline: 0;
            min-width: 300px;
            font-size: 16px;

            &:disabled {
                opacity: 0.5;
            }
        }
    }
}

.search-info-popup {
    margin-left: 0.5rem;
    align-self: center;
}

.search-filter-toggle {
    cursor: pointer;
    pointer-events: auto !important;
}

.search-filters {
    position: absolute;
    top: 4rem;
    margin-top: 5px;
    padding: 1rem;
    border-radius: 4px;
    left: 0;
    right: 0;
    display: grid;
    row-gap: 0.5rem;

    .filter-buttons {
        display: flex;
        justify-content: space-between;
    }

    .folder-paths {
        display: grid;
        min-height: 2.25em;
        height: auto;

        .tags {
            &:not(:last-child) {
                margin-bottom: 0;
            }

            .is-primary {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 350px;
                display: inline-block;
            }

            .is-delete {
                cursor: pointer;
            }
        }
    }

    .control.has-icons-right .icon {
        pointer-events: auto;
        color: inherit;
        cursor: pointer;
    }
}

.search-result-count {
    margin-top: -1.5rem;
    font-size: $size-7;
    position: fixed;
}

.search-result-item {
    padding: 1rem 0 0.5rem;
    border-bottom: 1px solid $border;

    &.is-active {
        box-shadow: inset 6px 0 0 $primary;
    }

    em {
        background: $yellow-light;
        font-weight: $weight-bold;
        font-style: normal;
    }

    .result-body {
        display: flex;
        padding: 0 0.5rem;
    }

    .result-tags {
        margin: 0;
        i {
            margin-right: 0.35rem;
        }
    }

    .result-image {
        text-align: center;
        margin-right: 1rem;
        cursor: pointer;
        padding-bottom: 0.5rem;
        min-width: 200px;

        img {
            margin: 0 auto;
            max-width: 200px;
            max-height: 250px;
            border: 1px solid $border;
            display: block;
        }
    }

    .result-title {
        word-break: break-all;
        cursor: pointer;
        color: $primary;
        font-weight: $weight-bold;
    }

    .result-plant {
        font-size: $size-7;
    }

    .result-path {
        color: $grey;
        font-size: $size-7;
        margin-bottom: 0.5rem;
        word-break: break-all;
    }

    .result-text {
        @extend .is-clipped;
        margin-bottom: 0.75rem;
    }

    &.is-equipment {
        .result-image {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.is-file-only {
        .result-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: auto;
            .result-source {
                color: $grey;
                font-size: $size-8;
            }
        }

        .result-title {
            color: inherit;
            cursor: auto;
        }
    }
}

.column .react-select-container {
    min-width: 150px;
}
