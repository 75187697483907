.modal-container {
    .modal-card {
        section.left {
            display: flex;
            align-items: center;

            .close-button {
                // align-self: flex-start;
            }
        }

        .modal-card-foot {
            &.flex-justify-space-between {
                justify-content: space-between;
            }
        }
    }
}
