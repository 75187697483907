.paging {
    padding: 0.5rem;
    background: transparent;
    border-top: 1px solid $border-hover;

    input {
        min-width: 5em;
        width: auto;
        text-align: center;
        margin: 0 0.25rem;
    }

    select {
        margin: 0 0.25rem;
    }
}

.search-result-paging {
    z-index: 9999;
    display: inline-block;
    background: rgba($white, 0.85);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 0 $radius $radius;
    box-shadow: 0 1px 2px $shadow;
    padding: 0.75rem 1rem;

    .paging-text {
        padding: 0 0.5rem;
    }
}
