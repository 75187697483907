.small {
    font-size: 0.875em;
}

$sizes: (("05", 0.25rem), ("1", 0.5rem), ("15", 0.75rem), ("2", 1rem), ("25", 1.5rem), ("3", 2rem));

@each $s in $sizes {
    .mx-#{nth($s, 1)} {
        margin-left: #{nth($s, 2)};
        margin-right: #{nth($s, 2)};
    }

    .my-#{nth($s, 1)} {
        margin-bottom: #{nth($s, 2)};
        margin-top: #{nth($s, 2)};
    }

    .ml-#{nth($s, 1)} {
        margin-left: #{nth($s, 2)};
    }

    .mr-#{nth($s, 1)} {
        margin-right: #{nth($s, 2)};
    }

    .mt-#{nth($s, 1)} {
        margin-top: #{nth($s, 2)};
    }

    .mb-#{nth($s, 1)} {
        margin-bottom: #{nth($s, 2)};
    }

    .ma-#{nth($s, 1)} {
        margin: #{nth($s, 2)};
    }

    .px-#{nth($s, 1)} {
        padding-left: #{nth($s, 2)};
        padding-right: #{nth($s, 2)};
    }

    .py-#{nth($s, 1)} {
        padding-bottom: #{nth($s, 2)};
        padding-top: #{nth($s, 2)};
    }

    .pl-#{nth($s, 1)} {
        padding-left: #{nth($s, 2)};
    }

    .pr-#{nth($s, 1)} {
        padding-right: #{nth($s, 2)};
    }

    .pt-#{nth($s, 1)} {
        padding-top: #{nth($s, 2)};
    }

    .pb-#{nth($s, 1)} {
        padding-bottom: #{nth($s, 2)};
    }

    .pa-#{nth($s, 1)} {
        padding: #{nth($s, 2)};
    }
}
