.dropzone {
    background: $white-bis;
    border: 1px solid $border;
    padding: 1rem;
    text-align: center;
    border-radius: $radius;
    position: relative;

    &.is-active {
        background: $white-ter;
        border-color: $primary;
    }

    .drop-icon {
        padding: 0.5rem;
        font-size: 2rem;
    }
}
