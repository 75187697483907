.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: rgba($color: $black, $alpha: 0.35);
}

.modal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    & > * {
        pointer-events: initial;
    }

    .modal-card {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 90%;
        box-shadow: 0 0 15px $shadow;
        overflow: visible;

        .modal-card-title {
            @extend .ellipsis;
            flex: 1;
        }

        .modal-card-body {
            overflow: visible;
        }

        .modal-card-foot {
            justify-content: flex-end;
        }

        &.is-scrollable {
            .modal-card-body {
                overflow-y: auto;
            }
        }
    }
}
