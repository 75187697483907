.tag-details {
    width: 420px;

    .pane {
        .pane-content {
            padding: 0;
        }

        .accordion {
            border: 0;
        }
    }
}
