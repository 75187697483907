.fill-height {
    height: 100%;
    flex-grow: 1;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.is-clickable {
    cursor: pointer;
}

.is-shrink {
    flex-shrink: 1;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex,
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-justify-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}

.flex-align-center {
    display: flex;
    align-items: center;
}
